import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

const IntroMobile = ({ intl }) => {
    const translate = (id) => intl.formatMessage({ id });
    const marketListCountries = [
        translate("argentina"),
        translate("australia"),
        translate("belgium"),
        translate("brazil"),
        translate("canada"),
        translate("chile"),
        translate("china"),
        translate("colombia"),
        translate("denmark"),
        translate("finland"),
        translate("france"),
        translate("germany"),
        translate("hong_kong"),
        translate("india"),
        translate("indonesia"),
        translate("ireland"),
        translate("italy"),
        translate("japan"),
        translate("malaysia"),
        translate("mexico"),
        translate("netherlands"),
        translate("new_zealand"),
        translate("nigeria"),
        translate("norway"),
        translate("peru"),
        translate("philippines"),
        translate("poland"),
        translate("russia"),
        translate("saudi_arabia"),
        translate("singapore"),
        translate("south_africa"),
        translate("south_korea"),
        translate("spain"),
        translate("sweden"),
        translate("taiwan"),
        translate("thailand"),
        translate("turkey"),
        translate("uae"),
        translate("uk"),
        translate("usa"),
        translate("vietnam"),
    ];
     return (
        <div className="block intro intro-mobile" data-section="intro">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div>
                            <p className="feature-text">{intl.formatMessage({ id: "home_header_marketguides_copy" })}{" "}</p>
                            <p>{intl.formatMessage({ id: "home_header_global_copy" })}{" "}</p>
                            <a className="button primary" href="#" title={intl.formatMessage({ id: "cta_download"})}>
                                {intl.formatMessage({ id: "cta_download"})}
                            </a>
                        </div>
                        <Controller>
                            <Scene
                                duration="100%"
                                 triggerElement=".intro-mobile"
                            >
                                <Timeline>
                                    <div>
                                        <Tween
                                            to={{
                                                translateX: "-100%"
                                            }}
                                            position={1}
                                        >
                                        <ul className="market-list">
                                            {marketListCountries.map((country, idx) => (
                                                <li key={idx}>{country}</li>
                                            ))}

                                        </ul>
                                        </Tween>
                                        <Tween
                                            to={{
                                                translateX: "100%"
                                            }}
                                            position={1}
                                        >
                                        <ul className="market-list">
                                            {marketListCountries.map((country, idx) => (
                                                <li key={idx}>{country}</li>
                                            ))}
                                        </ul>
                                        </Tween>
                                        <Tween
                                            to={{
                                                translateX: "-100%"
                                            }}
                                            position={1}
                                        >
                                        <ul className="market-list">
                                            {marketListCountries.reverse().map((country, idx) => (
                                                <li key={idx}>{country}</li>
                                            ))}
                                        </ul>
                                        </Tween>
                                    </div>
                                </Timeline>
                            </Scene>
                        </Controller>
                    </div>
                </div>
            </div>
          </div>

    )
}

export default injectIntl(IntroMobile)
