import React from 'react';
import { injectIntl } from "gatsby-plugin-intl"
import Link from './link';
import { Timeline, Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import heroMobileImg01 from "../images/home/sm-1.jpg"
import heroMobileImg02 from "../images/home/sm-2.jpg"
import heroMobileImg03 from "../images/home/sm-3.jpg"
import heroMobileImg04 from "../images/home/sm-4.jpg"
import useWindowSize from '../util/useWindowSize';

const HeroMobile = ({ intl }) => {
    const { height } = useWindowSize();

    return (
        <div className="block hero mobile" data-section="hero">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6">
                        <div>
                            <h1>{intl.formatMessage({ id: "home_header_heading" })}</h1>
                            <p>{intl.formatMessage({ id: "home_header_subheading" })}</p>
                            <Link className="button primary" to="/download" title={intl.formatMessage({ id: "cta_download" })}>{intl.formatMessage({ id: "cta_download" })}</Link>
                        </div>
                        <div>
                            <Controller>
                                <Scene
                                    duration={height * 3.5}
                                    triggerHook=".98"
                                >
                                    <Timeline>
                                        <Tween
                                            to={{
                                                translateX: "-60%"
                                            }}
                                        >
                                            <ul>
                                                <li><img className="hero-img-mobile" src={heroMobileImg01} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg02} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg03} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg04} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg01} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg02} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg03} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg04} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg01} alt="" /></li>
                                                <li><img className="hero-img-mobile" src={heroMobileImg02} alt="" /></li>
                                            </ul>
                                        </Tween>
                                    </Timeline>
                                </Scene>
                            </Controller>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default injectIntl(HeroMobile);
