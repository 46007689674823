import React, { useRef, useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { Scene, Controller } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import classnames from 'classnames';
import useWindowSize from '../util/useWindowSize';
import { getWindow } from 'ssr-window';
import scrollImg01 from "../images/trends/trend_item_01.jpg"
import scrollImg02 from "../images/trends/trend_item_02.jpg"
import scrollImg03 from "../images/trends/trend_item_03.jpg"
import scrollImg04 from "../images/trends/trend_item_04.jpg"
import smallScrollImg01 from "../images/trends/mobile_trend_item_01.jpg"
import smallScrollImg02 from "../images/trends/mobile_trend_item_02.jpg"
import smallScrollImg03 from "../images/trends/mobile_trend_item_03.jpg"
import smallScrollImg04 from "../images/trends/mobile_trend_item_04.jpg"
import statDecorationChart01 from "../images/trends/trend_decororation_chart_01.svg"
import statDecorationChart02 from "../images/trends/trend_decororation_chart_02.svg"

const window = getWindow();

const SideScroll = ({ intl, getPaginationData, trackingSection }) => {
    const { width } = useWindowSize();
    const gsapTween = useRef(null);
    const gsapTimeline = useRef(null);
    const slideTitles = useRef(null);
    const ref= useRef(null);
    const [progress, setProgress] = useState(0);
    const [titleHeight, setTitleHeight] = useState(0)
    const [slideWidth, setSlideWidth] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            const {current} = gsapTween;
            const gsap = current.getGSAP();


            gsap.eventCallback('onUpdate', () => {
                const currentProgress = gsap.parent.progress();
                setProgress(currentProgress);

                // if (currentProgress >= 0.5 && progress < 0.5) {
                //     // set greater
                //     // setProgress(gsapTween.current.getGSAP().parent.progress());
                //     setProgress(1);
                // } else if (currentProgress <= 0.5 && progress > 0.5) {
                //     setProgress(0);

                // }
            });

        }, 0);
    }, [gsapTween]);

    useEffect(() => {
        const { current } = slideTitles;
        let heights = [];
        Array.from(current.childNodes).forEach(el => {
            heights.push(el.offsetHeight);
        })

        if (progress > 0.5) {
            setTitleHeight(heights[1]);
        } else {
            setTitleHeight(heights[0]);
        }
    }, [progress, slideTitles]);

    useEffect(() => {

        const { current: { targets } } = gsapTween;
        const current = targets[0];
        let slidesWidth = 0;

        Array.from(current.querySelectorAll('.item')).forEach(item => {
            const style = window.getComputedStyle ? getComputedStyle(item, null) : item.currentStyle;
            const marginLeft = parseInt(style.marginLeft) || 0;
            const marginRight = parseInt(style.marginRight) || 0;
            const itemWidth = marginLeft + marginRight + item.clientWidth;
            slidesWidth = slidesWidth + itemWidth;
        });


        setSlideWidth(`-${slidesWidth - width}px`);
        return () => {

        }
    }, [gsapTween]);

    useEffect(() => {
        if (ref.current) getPaginationData('Payment Trends', ref);
    }, [ref.current]);

    return (
        <div ref={ref}>
            <Controller>
                <Scene
                    duration="400%"
                    triggerHook="onLeave"
                    pin
                >
                    <Timeline
                        ref={gsapTimeline}
                    >
                        <div className="block standard title scroll-title" data-section={trackingSection}>
                          <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-10">
                                    {/* <p className="snippet">
                                        {intl.formatMessage({ id: "home_payment_heading" })}
                                    </p>*/}
                                    <div className={classnames({
                                        'scroll-items-title': true,
                                        switch: progress >= 0.5
                                    })}
                                    style={{
                                        height: `${titleHeight}px`
                                    }}
                                    ref={slideTitles}
                                    >
                                        <h2 className="green">
                                            {intl.formatMessage({ id: "home_payment_heading1" })}
                                        </h2>
                                        <h2 className="purple">
                                            {intl.formatMessage({ id: "home_ecom_heading" })}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <Tween
                            to={{
                                translateX: slideWidth,
                            }}
                            ref={gsapTween}
                        >
                            <div className="block standard scroll-items">
                                <div className="item green">
                                    {width >= 1200 ?
                                        <img src={scrollImg01} alt="" /> :
                                        <img src={smallScrollImg01} alt="" />
                                    }
                                    <div className="item-content">

                                        <h4>
                                            {intl.formatMessage({
                                                id: "home_payment_dataheading1",
                                            })}
                                        </h4>
                                       <p>
                                            {intl.formatMessage({
                                                id: "home_payment_datacopy1",
                                            })}
                                        </p>
                                        <span className="xl-stat">{'32' + intl.formatMessage({ id: 'argentina_fast_stat_percent' })}</span>
                                        {/* <span>
                                            {intl.formatMessage({
                                                id: "home_payment_datacopy1",
                                            })}
                                        </span>  */}
                                    </div>
                                </div>
                                <div className="item green">
                                    {width >= 1200 ?
                                        <img src={scrollImg02} alt="" /> :
                                        <img src={smallScrollImg02} alt="" />
                                    }
                                    <div className="item-content">
                                        <h4>
                                            {intl.formatMessage({
                                                id: "home_payment_dataheading2",
                                            })}
                                        </h4>
                                        <p>
                                            {intl.formatMessage({
                                                id: "home_payment_datacopy2",
                                            })}
                                        </p>
                                        <img
                                            className="stat-decoration chart"
                                            src={statDecorationChart01}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="item green text">
                                    <p>
                                        {intl.formatMessage({
                                            id: "home_payment_datacopy3"
                                        })}
                                    </p>
                                </div>
                                <div className="item purple">
                                    {width >= 1200 ?
                                        <img src={scrollImg03} alt="" /> :
                                        <img src={smallScrollImg03} alt="" />
                                    }
                                    <div className="item-content">
                                        <h4>
                                            {" "}
                                            {intl.formatMessage({
                                                id: "home_ecom_heading1",
                                            })}
                                        </h4>
                                        <p>
                                            {intl.formatMessage({
                                                id: "home_ecom_copy1",
                                            })}
                                        </p>
                                        <span className="xl-stat stat-decoration-accelerator">{'19' + intl.formatMessage({ id: 'argentina_fast_stat_percent' })}</span>
                                    </div>
                                </div>
                                <div className="item purple">
                                    {width >= 1200 ?
                                        <img src={scrollImg04} alt="" /> :
                                        <img src={smallScrollImg04} alt="" />
                                    }
                                    <div className="item-content">
                                        <h4>
                                            {intl.formatMessage({
                                                id: "home_ecom_heading2",
                                            })}
                                        </h4>
                                        <p>
                                            {intl.formatMessage({
                                                id: "home_ecom_copy2",
                                            })}{" "}
                                        </p>
                                        <img
                                            className="stat-decoration chart"
                                            src={statDecorationChart02}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="item purple text">
                                    <p>
                                        {intl.formatMessage({
                                            id: "home_ecom_subcopy2"
                                        })}
                                    </p>
                                </div>
                            </div>
                        </Tween>
                    </Timeline>

                </Scene>
            </Controller>
        </div>
    )
}

export default injectIntl(SideScroll);
