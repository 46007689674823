import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { sentenceCase } from "change-case";
import useWindowSize from "../util/useWindowSize"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import SlidingStat from "../components/sliding-stat"
import Trends from "../components/trends"
import SideScroll from "../components/side-scroll"
import Cta from "../components/cta"
import Summary from "../components/summary"
import Footer from "../components/footer"
import Boilerplate from "../components/boilerplate"

import HeroMobile from "../components/hero-mobile"
import IntroMobile from "../components/intro-mobile"
import Pagination from "../components/pagination"
import SideScrollMobile from '../components/side-scroll-mobile'

import StatImage1 from "../images/splits/home_slider_bg_01.jpg"
import StatImage2 from "../images/splits/home_slider_bg_02.jpg"

import SummaryBackground from "../images/summary/summary_home.jpg"

import TrendImage from "../images/splits/split_payment_trends.jpg"
import MarketGuideBlock from "../components/marketGuideBlock"
import MarketGuideBlockMobile from "../components/marketGuideBlockMobile"

const IndexPage = ({ intl }) => {
  const { width } = useWindowSize()

  const [paginationItems, setPaginationItems] = useState([
      {
          id: 'home_nav_1',
          text: 'Home',
          scrollTo: null,
      },
      {
          id: 'home_nav_2',
          text: 'The payment shift',
          scrollTo: null,
      },
      {
          id: 'home_nav_3',
          text: 'Payment trends',
          scrollTo: null,
      },
      {
          id: 'home_nav_4',
          text: 'Predictions',
          scrollTo: null,
      },
      {
          id: 'home_nav_5',
          text: 'The pandemic effect',
          scrollTo: null,
      },
  ]);

  const getPaginationData = (text, ref) => {
      const sentenceCased = sentenceCase(text);
      const updated = [...paginationItems];

      for (const i in updated) {
          if (sentenceCase(updated[i].text) == sentenceCased) {
             updated[i].scrollTo = ref.current;
             break;
          }
      }

      setPaginationItems(paginationItems => [...updated]);
  }

  return (
    <Layout>
      <SEO title="Home" />
      {width >= 1200 ? <Pagination items={paginationItems} /> : null}
      <div className="main">
        <>
          {width >= 992 ? (
            <Hero getPaginationData={getPaginationData} />
          ) : (
            <>
              <HeroMobile />
              <IntroMobile />
            </>
          )}
        </>

        {/* CTA */}
        <Cta
          header={intl.formatMessage({ id: "home_header_explore" })}
          content={intl.formatMessage({ id: "home_header_explore_copy" })}
          button={intl.formatMessage({ id: "cta_download" })}
          trackingSection="cta-1"
        />
          {/* TRENDS INTRO */}
          <Trends
            image={TrendImage}
            heading={intl.formatMessage({ id: "home_trends_heading" })}
            paras={intl.formatMessage({ id: "home_trends_copy" })}
            cssClass={["purple", "trends"]}
            getPaginationData={getPaginationData}
            trackingSection="trends"
          />

          {/* SCROLL ITEMS */}
          {width < 1200 ? <SideScrollMobile /> : <SideScroll getPaginationData={getPaginationData} />}
          {/* SLIDING STATS */}
          <SlidingStat
            title={intl.formatMessage({ id: "home_future_heading" })}
            stat1Title={intl.formatMessage({ id: "home_future_dataheading1" })}
            stat1Value={intl.formatMessage({ id: "home_future_data" })}
            stat1Description={intl.formatMessage({
              id: "home_future_datacopy1",
            })}
            stat1Image={StatImage1}
            stat2Title={intl.formatMessage({ id: "home_future_dataheading2" })}
            stat2Value={'42' + intl.formatMessage({ id: 'argentina_fast_stat_percent' })}
            stat2Description={intl.formatMessage({
              id: "home_future_datacopy2",
            })}
            stat2Image={StatImage2}
            getPaginationData={getPaginationData}
            trackingSection="stats"
          />

        {/* CTA */}
        <Cta
          header={intl.formatMessage({ id: "home_commerce_heading" })}
          content={intl.formatMessage({ id: "home_commerce_copy" })}
          button={intl.formatMessage({ id: "cta_download" })}
          trackingSection="cta-2"
        />

        {/* SUMMARY BLOCK */}
        <Summary
          background={SummaryBackground}
          heading={intl.formatMessage({ id: "home_pandemic_heading" })}
          para1={intl.formatMessage({ id: "home_pandemic_copy" })}
          para2={intl.formatMessage({ id: "home_pandemic_copy2" })}
          getPaginationData={getPaginationData}
          trackingSection="summary"
        />

        {width < 1200 ?
            <MarketGuideBlockMobile trackingSection="guide-block" homePage />
            :
            <MarketGuideBlock trackingSection="guide-block" homePage />
        }
          {/* FOOTER CTA */}
          <Boilerplate trackingSection="boilerplate" />
        {/* FOOTER */}
        <Footer trackingSection="footer" />
      </div>
    </Layout>
  )
}

export default injectIntl(IndexPage)
