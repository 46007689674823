import React, { useEffect, useRef } from "react"
import classNames from 'classnames'
import parallaxImg01 from "../images/home/image_01.jpg"
import parallaxImg02 from "../images/home/image_02.jpg"
import parallaxImg03 from "../images/home/image_03.jpg"
import parallaxImg04 from "../images/home/image_04.jpg"
import { Tween, Timeline } from "react-gsap"
import { Controller, Scene } from "react-scrollmagic"
import { injectIntl } from "gatsby-plugin-intl"
import Link from './link';
import useWindowSize from "../util/useWindowSize";

/*
    INTRO Notes
    - Market list li (country name outlines) need to scroll-forward and reverse parallax/faster on scroll to reveal more countries (Overflow hidden)
*/

const Hero = ({ intl, getPaginationData }) => {
    const { width } = useWindowSize();
    const translate = (id) => intl.formatMessage({ id });

    const marketListCountries = [
        translate("argentina"),
        translate("australia"),
        translate("belgium"),
        translate("brazil"),
        translate("canada"),
        translate("chile"),
        translate("china"),
        translate("colombia"),
        translate("denmark"),
        translate("finland"),
        translate("france"),
        translate("germany"),
        translate("hong_kong"),
        translate("india"),
        translate("indonesia"),
        translate("ireland"),
        translate("italy"),
        translate("japan"),
        translate("malaysia"),
        translate("mexico"),
        translate("netherlands"),
        translate("new_zealand"),
        translate("nigeria"),
        translate("norway"),
        translate("peru"),
        translate("philippines"),
        translate("poland"),
        translate("russia"),
        translate("saudi_arabia"),
        translate("singapore"),
        translate("south_africa"),
        translate("south_korea"),
        translate("spain"),
        translate("sweden"),
        translate("taiwan"),
        translate("thailand"),
        translate("turkey"),
        translate("uae"),
        translate("uk"),
        translate("usa"),
        translate("vietnam"),
    ];

    const ref = useRef(null);
    const block = useRef(null);

    useEffect(() => {
        if (ref.current) getPaginationData('Home', ref)
    }, []);

    useEffect(() => {
        const { current } = block;
        current.parentNode.style.width = `${width}px`;

    }, [width])

    return (
        <div ref={ref}>
        <Controller>
            <Scene
                duration={2000}
                pin
                pinSettings={{ pushFollowers: true }}
                triggerHook="0"
                classToggle="visible"
            >
                <Timeline>

                    <div className="block hero" data-section="hero" ref={block}>

                        {/* Parallax notes
                            This will be a nightmare - Not only the positioning but also the scaling and that parallax-item-05 needs to grow to be the background for the .intro section. Lets discuss when we get around to it.
                        */}
                        <div className="hero-images">
                            <Tween
                                to={{

                                    translateY: "-500%"
                                }}
                                position="0"

                            >
                                <div className="parallax-item parallax-item-01">
                                    <img src={parallaxImg01} alt="" />
                                </div>
                            </Tween>
                            <Tween
                                to={{
                                    translateX: "100%",
                                    translateY: "-100%",
                                }}
                                position="0"
                            >
                                <div className="parallax-item parallax-item-02">
                                    <img src={parallaxImg02} alt="" />
                                </div>
                            </Tween>
                            <Tween
                                to={{
                                    top: "-100%",
                                }}
                                position="0"
                            >
                                <div className="parallax-item parallax-item-03">
                                    <img src={parallaxImg03} alt="" />
                                </div>
                            </Tween>
                            <Tween
                                to={{
                                    translateX: "100%",
                                    translateY: "-100%",

                                }}
                                position="0"
                            >
                                <div className="parallax-item parallax-item-04">
                                    <img src={parallaxImg04} alt="" />
                                </div>
                            </Tween>
                        </div>
                        <Tween
                            from={{
                                zIndex: 100,
                            }}
                            to={{

                                bottom: "100%"
                            }}
                            position="0"
                        >
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="content col-lg-6">
                                        <h1>{intl.formatMessage({ id: "home_header_heading" })}</h1>
                                        <p className="hero-intro">{intl.formatMessage({ id: "home_header_subheading" })}</p>
                                        <Link className="button primary" to='/download' title={intl.formatMessage({ id: "cta_download" })}>
                                            {intl.formatMessage({ id: "cta_download" })}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Tween>
                        <Tween
                            to={{
                                scale: 1,


                            }}
                            position="0"
                        >
                            <div className="hero-image" />
                        </Tween>
                        <div className="block intro" data-section="intro">
                            <div className="container-fluid">
                                <div className="row">
                                    <Tween
                                        from={{
                                            opacity: 0,
                                            zIndex: -1,
                                            visibility: "hidden",
                                        }}
                                        to={{
                                            opacity: 1,
                                            zIndex: 1,
                                            visibility: "visible",
                                        }}
                                        position="1"
                                    >
                                        <div className="content col-lg-6">
                                            <p className="feature-text">
                                                {intl.formatMessage({ id: "home_header_marketguides_copy" })}{" "}
                                                {/*<span className="text-bg-purple">
                                                    {intl.formatMessage({ id: "this-is-missing" })}
                                                </span>*/}
                                            </p>
                                            <p>
                                                {intl.formatMessage({ id: "home_header_global_copy" })}{" "}
                                                {/*<span className="text-bg-teal">
                                                    {intl.formatMessage({ id: "this-is-missing" })}
                                                </span>*/}
                                            </p>
                                            <Link className="button primary" to="/download" title={intl.formatMessage({ id: "cta_download" })}>
                                                {intl.formatMessage({ id: "cta_download" })}
                                            </Link>
                                        </div>
                                    </Tween>
                                    <div className="content col-lg-6">
                                        <Tween
                                            from={{
                                                opacity: 0,
                                                translateY: "100px",
                                                top: -100,
                                            }}
                                            to={{
                                                top: "auto",
                                                opacity: 1,
                                                translateY: 0,
                                                top: -200,
                                                position: "absolute",
                                            }}
                                            position="1"
                                        >
                                            <ul className="market-list">
                                                {marketListCountries.map((country, idx) => (
                                                    <li
                                                        className={classNames({ highlight: country === 'argentina' })}
                                                        key={idx}
                                                    >
                                                        {country}
                                                    </li>
                                                ))}
                                            </ul>
                                        </Tween>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <Tween
                                to={{
                                    opacity:0,
                                }}
                                position="0"
                            >
                                <p className="browser-notice">This site is best viewed using a modern browser.</p>
                            </Tween>
                    </div>
                </Timeline>
            </Scene>

        </Controller>
        </div>
    );
}

export default injectIntl(Hero);
