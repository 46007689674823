import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import smallScrollImg01 from "../images/trends/mobile_trend_item_01.jpg"
import smallScrollImg02 from "../images/trends/mobile_trend_item_02.jpg"
import smallScrollImg03 from "../images/trends/mobile_trend_item_03.jpg"
import smallScrollImg04 from "../images/trends/mobile_trend_item_04.jpg"
import statDecorationChart01 from "../images/trends/trend_decororation_chart_01.svg"
import statDecorationChart02 from "../images/trends/trend_decororation_chart_02.svg"


const SideScrollMobile = ({ intl, trackingSection}) => (
    <div className="block standard scroll-items-mobile" data-section={trackingSection}>
        <div className="scroll-items-groups">
            <div className="scroll-items-group">
                <div className="scroll-items-group-header">
                    <h2 className="green">
                        {intl.formatMessage({ id: "home_payment_heading1" })}
                    </h2>
                </div>
                <div className="scroll-items-items">
                    <div className="item green">
                        <img src={smallScrollImg01} alt="" />
                        <div className="item-content">

                            <h4>
                                {intl.formatMessage({
                                    id: "home_payment_dataheading1",
                                })}
                            </h4>
                            <p>
                                {intl.formatMessage({
                                    id: "home_payment_datacopy1",
                                })}
                            </p>
                            <span className="xl-stat">{'32' + intl.formatMessage({ id: 'argentina_fast_stat_percent' })}</span> {/* this needs translating */}
                            {/* <span>
                                            {intl.formatMessage({
                                                id: "home_payment_datacopy1",
                                            })}
                                        </span>  */}
                        </div>
                    </div>
                    <div className="item green">
                        <img src={smallScrollImg02} alt="" />
                        <div className="item-content">
                            <h4>
                                {intl.formatMessage({
                                    id: "home_payment_dataheading2",
                                })}
                            </h4>
                            <p>
                                {intl.formatMessage({
                                    id: "home_payment_datacopy2",
                                })}
                            </p>
                            <img
                                className="stat-decoration chart"
                                src={statDecorationChart01}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="item green text">
                        <p>
                            {intl.formatMessage({
                                id: "home_payment_datacopy3"
                            })}
                        </p>
                    </div>

                </div>
            </div>
            <div className="scroll-items-group">
                <div className="scroll-items-group-header">
                    <h2 className="purple">
                        {intl.formatMessage({ id: "home_ecom_heading" })}
                    </h2>
                </div>
                <div className="scroll-items-items">
                    <div className="item purple">
                        <img src={smallScrollImg03} alt="" />
                        <div className="item-content">
                            <h4>
                                {" "}
                                {intl.formatMessage({
                                    id: "home_ecom_heading1",
                                })}
                            </h4>
                            <p>
                                {intl.formatMessage({
                                    id: "home_ecom_copy1",
                                })}
                            </p>
                            <span className="xl-stat stat-decoration-accelerator">
                                {'19' + intl.formatMessage({ id: 'argentina_fast_stat_percent' })}
                            </span>
                        </div>
                    </div>
                    <div className="item purple">
                        <img src={smallScrollImg04} alt="" />
                        <div className="item-content">
                            <h4>
                                {intl.formatMessage({
                                    id: "home_ecom_heading2",
                                })}
                            </h4>
                            <p>
                                {intl.formatMessage({
                                    id: "home_ecom_copy2",
                                })}{" "}
                            </p>
                            <img
                                className="stat-decoration chart"
                                src={statDecorationChart02}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="item purple text">
                        <p>
                            {intl.formatMessage({
                                id: "home_ecom_subcopy2"
                            })}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default injectIntl(SideScrollMobile);
